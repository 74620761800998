// Variable overrides
/* latin */



$border-radius: .1rem !default;

.price {
  font: .8rem/1.2 'Atlas Typewriter LC Web' !important;
}

$sidebar-light-brand-bg: #c8ced3;
$sidebar-dark-brand-bg: #c8ced3;
$header-dark-bg: #fff;
$header-light-bg: #fff;
$sidebar-light-bg: #3b3d3e;