// Here you can add other styles
.book-container {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.book-asks {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 50%;
}

.book-bids {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  bottom: 0;
}